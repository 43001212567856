const vars = {}

vars['font-title'] = `'IBM Plex Serif', sans-serif`
vars['font-text'] = `'Overpass', sans-serif`

vars['color-border'] = '#c4c4c4'
vars['color-black'] = '#333'
vars['color-gray'] = '#666'
vars['color-green'] = '#0d9999'
vars['color-red'] = '#F46161'
vars['color-danger'] = vars['color-red']
vars['color-primary'] = vars['color-green']
vars['color-title'] = vars['color-black']
vars['color-text'] = vars['color-gray']

export default vars
