const navLinks = [
  {
    title: 'Projetos',
    href: '/projetos',
  },
  {
    title: 'Blog',
    href: '/blog',
  },
  {
    title: 'Transparência',
    href: '/transparencia',
  },
  {
    title: 'Contato',
    href: '/contato',
  },
  {
    title: 'Como contribuir',
    href: '/como-contribuir',
  },
]

export default navLinks
