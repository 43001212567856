module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Admin - Fundação Aprisco"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/templates/base/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fundação Aprisco","short_name":"Fundação Aprisco","start_url":"/","background_color":"#fff","theme_color":"##0d9999","display":"standalone","icon":"src/images/fundacao-aprisco-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1acbb17b71f9a38364d5d15f5c4d143b"},
    }]
